import React, { Component } from 'react';
import styled from 'styled-components';

import Hero from 'components/Hero';
import TabDescription from 'components/TabDescription';
import WhiteBackground from 'components/WhiteBackground';
import Form from './elements/Form/Form';
import GoogleMap from './elements/Map/Map';
import Page from 'components/Page';
import Footer from 'components/Footer';
import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';

import figures from 'src/data/get-in-touch/figures';

class GetInTouch extends Component {
  render() {
    return (
      <Root>
        <Hero
          title="Get in Touch"
          description="Whether you want to learn more about us or inquire about joint ventures, feel free to get in touch."
          image={{
            src: require('src/assets/images/illustrations/hero-contact.png'),
          }}
          figures={figures}
        />
        <TabDescription
          title="Momenta Properties"
          description={
            <>
              <a
                href="https://www.google.com/maps/place/1166+Alberni+St,+Vancouver,+BC+V6E+1A5/@49.2861649,-123.1278127,17z/data=!3m1!4b1!4m5!3m4!1s0x54867180de8abc85:0x875385a65cf308d9!8m2!3d49.2861649!4d-123.125624"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#262626' }}
              >
                1108-1166 Alberni Street
                <br />
                Vancouver, BC V6E 3Z3
              </a>
              <br />
              <br />
              T. <a href="tel:604-771-3813">604-771-3813</a>
              <br />
              E.{' '}
              <a
                href="mailto: info@momentaproperties.ca"
                target="_blank"
                rel="noopener noreferrer"
              >
                info@momentaproperties.ca
              </a>
            </>
          }
          source="get-in-touch"
        />
        <Container>
          <WhiteBackground>
            <Page>
              <Form />
              <GoogleMap />
            </Page>
          </WhiteBackground>
        </Container>
        <Footer />
      </Root>
    );
  }
}

export default GetInTouch;

const Root = styled.main``;

const Container = styled.div`
  > div {
    padding-top: ${vw(164)};
    padding-bottom: ${vw(60)};
  }
  @media (min-width: ${media.tablet}) {
    > div {
      padding-top: ${vwTablet(60)};
      padding-bottom: ${vwTablet(32)};
    }
  }
  @media (min-width: ${media.desktop}) {
    > div {
      padding-top: ${vwDesktop(166)};
      padding-bottom: ${vwDesktop(60)};
      > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
  @media (min-width: ${media.xlarge}) {
    > div {
      padding-top: 166px;
      padding-bottom: 60px;
    }
  }
`;
