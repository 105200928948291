import { createGlobalStyle } from 'styled-components';

import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  @keyframes fadeInUp {
    0% {
      display:none;
      opacity: 0;
      transform: translateY(60px);
    }
    1%{
      display:block;
      opacity: 0;
      transform: translateY(60px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .fadeInUp {
   animation-name: fadeInUp;
   animation-fill-mode: backwards;
 }

  html,
  body,
  #app {
    width: 100%;
    height: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 0;
    font-family:${({ theme }) => theme.font.body};
  }

  body {
    font-family: 'Roboto';
    background-color:#F7F6F4;
  }

  a,
  button {
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
    color: unset;
  }

  main {
    text-align: center;
    position: absolute;
    top: ${vw(80)};
    left: 0;
    width: 100%;
    height: 100%;
    @media(min-width:${media.tablet}){
      top:${vwTablet(80)};
    }
    @media(min-width:${media.desktop}){
      top:${vwDesktop(93)};
    }
    @media(min-width:${media.xlarge}){
      top: 93px;
    }
  }

  h1{
    color:${({ theme }) => theme.color.navyBlue};
    font-size:${vw(36)};
    font-weight: 500;
    letter-spacing:${vw(1)};
    line-height: normal;
    @media(min-width:${media.tablet}){
      font-size:${vwTablet(36)};
      letter-spacing:${vwTablet(1)};
    }
    @media(min-width:${media.desktop}){
      font-size:${vwDesktop(60)};
      letter-spacing:${vwDesktop(1.5)};
    }
    @media(min-width:${media.xlarge}){
      font-size: 60px;
      letter-spacing: 1.5px;
    }
  }

  h2{
    color:${({ theme }) => theme.color.navyBlue};
    font-size:${vw(24)};
    font-weight: 500;
    letter-spacing: ${vw(0.6)};
    line-height: normal;
    @media(min-width:${media.tablet}){
      font-size:${vwTablet(24)};
      letter-spacing:${vwTablet(0.6)};
    }
    @media(min-width:${media.desktop}){
      font-size:${vwDesktop(30)};
      letter-spacing:${vwDesktop(0.8)};
    }
    @media(min-width:${media.xlarge}){
      font-size: 30px;
      letter-spacing: 0.8px;
    }
  }

  h3{
    color:${({ theme }) => theme.color.navyBlue};
font-size:${vw(18)};
font-weight:bold;
letter-spacing: ${vw(0.5)};
@media(min-width:${media.tablet}){
  font-size:${vwTablet(18)};
  letter-spacing:${vwTablet(0.5)};
}
    @media(min-width:${media.desktop}){
      font-size:${vwDesktop(24)};
    }
    @media(min-width:${media.xlarge}){
      font-size: 24px;
    }
  }

  h4{
    color:${({ theme }) => theme.color.black};
    font-size:${vw(16)};
    font-weight: 500;
    letter-spacing: ${vw(0.4)};
    @media(min-width:${media.tablet})
    {
      font-size:${vwTablet(16)};
      letter-spacing:${vwTablet(0.4)};
    }
    @media(min-width:${media.desktop}){
      font-size:${vwDesktop(18)};
      letter-spacing:${vwDesktop(0.4)};
    }
    @media(min-width:${media.xlarge}){
      font-size: 18px;
      letter-spacing: 0.4px;
    }
  }

  p{
    color:${({ theme }) => theme.colorBlack};
    font-size:${vw(18)};
    line-height: 1.56;
    letter-spacing: ${vw(0.5)};
    font-weight: 300;
    @media(min-width:${media.tablet}){
      font-size:${vwTablet(18)};
      letter-spacing:${vwTablet(0.5)};
    }
    @media(min-width:${media.desktop}){
      font-size:${vwDesktop(20)};
      line-height: 1.5;
      letter-spacing:${vwDesktop(0.5)};
    }
    @media(min-width:${media.xlarge}){
      font-size: 20px;
    }
  }

  label{
    color:${({ theme }) => theme.color.gray};
    font-size: ${vw(15)};
    font-weight: 300;
    line-height: 1.6;
    letter-spacing: ${vw(0.4)};
    @media(min-width:${media.tablet}){
      font-size:${vwTablet(15)};
      letter-spacing:${vwTablet(0.4)};
      margin-bottom: ${vwTablet(4)};

    }
    @media(min-width:${media.desktop}){
      font-size:${vwDesktop(15)};
      letter-spacing:${vwDesktop(0.4)};
      margin-bottom:${vwDesktop(4)};
    }
  }

  .small-body{
    font-size:${vw(15)};
  letter-spacing:${vw(0.4)};
  line-height: 1.6;
  @media(min-width:${media.tablet}){
    font-size:${vwTablet(15)};
    letter-spacing:${vwTablet(0.4)};

  }
  @media(min-width:${media.desktop}){
    font-size:${vwDesktop(16)};
  }
  @media(min-width:${media.xlarge}){
    font-size:16px;
  }
  }



  @font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/Roboto-Regular.woff2') format('woff2'),
      url('/assets/fonts/Roboto-Regular.woff') format('woff');
  }

  @font-face {
    font-family: 'Helvetica Neue';
    src: url('/assets/fonts/HelveticaNeue-Light.woff2') format('woff2'),
      url('/assets/fonts/HelveticaNeue-Light.woff') format('woff');
      font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Helvetica Neue';
    src: url('/assets/fonts/HelveticaNeue-Medium.woff2') format('woff2'),
      url('/assets/fonts/HelveticaNeue-Medium.woff') format('woff');
      font-weight: 500;
    font-style: normal;
  }



`;
