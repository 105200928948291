import React from 'react';
import styled from 'styled-components';

import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import Page from 'components/Page';

const FeaturedImage = () => {
  return (
    <Root>
      <Page>
        <Image
          tabletHidden
          src={require('src/assets/images/about-us/davie-street.jpg')}
        />
        <ImageContainer
          background={require('src/assets/images/about-us/davie-street.jpg')}
        >
          <TextContainer>
            <h2>What We Do</h2>
            <p className="small-body">
              Another way in which we are breaking with convention is our
              singular focus on multi-use buildings. There was a time when most
              people lived where they worked and worked where they lived. Shop
              owners would live above their stores, and craftsman had workshops
              in their homes. Although that way of life is no longer possible,
              our focus on including commercial and retail components in every
              community is our way of improving everyone’s standard of living,
              reducing our impact on the environment, and increasing the
              vibrancy and wellbeing of the entire neighbourhood.
            </p>
          </TextContainer>
        </ImageContainer>
      </Page>
    </Root>
  );
};

export default FeaturedImage;

const Root = styled.div`
  padding-top: ${vw(592)};
  @media (min-width: ${media.tablet}) {
    padding-top: 0;
    margin-bottom: ${vwTablet(48)};
  }
  @media (min-width: ${media.desktop}) {
    padding-top: ${vwDesktop(322.2)};
    margin-bottom: ${vwDesktop(48)};
  }
  @media (min-width: ${media.xlarge}) {
    padding-top: 322.2px;
    margin-bottom: 48px;
  }
`;

const ImageContainer = styled.div`
  background: gray;
  background-image: ${props => props.background && `url(${props.background})`};
  @media (min-width: ${media.tablet}) {
    height: ${vwTablet(516)};
    background-size: cover;
    background-position: center;
    padding-top: ${vwTablet(32)};
    padding-left: ${vwTablet(305)};
    padding-bottom: ${vwTablet(32)};
  }
  @media (min-width: ${media.desktop}) {
    height: ${vwDesktop(680)};
    position: Relative;
  }
  @media (min-width: ${media.xlarge}) {
    height: 680px;
  }
`;

const TextContainer = styled.div`
  background: white;
  text-align: left;
  padding-top: ${vw(32)};
  padding-bottom: ${vw(60)};
  h2 {
    margin-bottom: ${vw(16)};
  }

  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(24)};
    width: ${vwTablet(342)};
    h2 {
      margin-bottom: ${vwTablet(16)};
    }
  }

  @media (min-width: ${media.desktop}) {
    position: absolute;
    padding: ${vwDesktop(48)};
    right: ${vwDesktop(110)};
    top: ${vwDesktop(80)};
    width: ${vwDesktop(436)};
    h2 {
      margin-bottom: ${vwDesktop(24)};
    }
    p {
      line-height: 1.63;
      letter-spacing: ${vwDesktop(0.4)};
    }
  }
  @media (min-width: ${media.xlarge}) {
    padding: 48px;
    right: 110px;
    top: 80px;
    width: 436px;
    h2 {
      margin-bottom: 24px;
    }
    p {
      letter-spacing: 0.4px;
    }
  }
`;

const Image = styled.img`
  width: 100%;
  height: ${vw(260)};
  object-fit: cover;
  @media (min-width: ${media.tablet}) {
    display: ${props => props.tabletHidden && 'none'};
  }
`;
