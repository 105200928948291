import React from 'react';
import styled from 'styled-components';

import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import Button from 'components/Button';
import WhiteBackground from 'components/WhiteBackground';
import Page from 'components/Page';

const ContactPanel = () => {
  return (
    <Root background={require('src/assets/images/home/business-meeting.jpg')}>
      <WhiteBackground tabletHidden>
        <Page>
          <ContactContainer
            background={require('src/assets/images/home/business-meeting.jpg')}
          >
            <Image
              src={require('src/assets/images/home/business-meeting.jpg')}
              alt="Business Meeting"
            />
            <TextContainer>
              <h2>Contact Us</h2>

              <p className="small-body">
                For more information on joint ventures, get in touch at{' '}
                <a
                  href="mailto:partnerships@momentaproperties.ca"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  partnerships@momentaproperties.ca
                </a>
              </p>

              <Button path="/get-in-touch" label="Get in Touch" />
            </TextContainer>
          </ContactContainer>
        </Page>
      </WhiteBackground>
    </Root>
  );
};

export default ContactPanel;

const Root = styled.div`
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(32)} 0;
    img {
      display: none;
    }
  }
  @media (min-width: ${media.desktop}) {
    height: ${vwDesktop(542)};
    width: 100%;
    padding-top: ${vwDesktop(60)};
    padding-bottom: ${vwDesktop(60)};
  }
  @media (min-width: ${media.xlarge}) {
    height: 542px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
`;

const ContactContainer = styled.div`
  @media (min-width: ${media.tablet}) {
    background-image: ${props => `url(${props.background})`};
    background-size: cover;
    background-position: center;
    padding-top: ${vwTablet(32)};
    padding-left: ${vwTablet(305)};
    padding-bottom: ${vwTablet(32)};
  }
  @media (min-width: ${media.desktop}) {
    padding-top: ${vwDesktop(48)};
    padding-bottom: ${vwDesktop(86)};
    padding-left: ${vwDesktop(655)};
  }
  @media (min-width: ${media.xlarge}) {
    padding-top: 48px;
    padding-bottom: 86px;
    padding-left: 655px;
  }
`;

const Image = styled.img`
  height: ${vw(260)};
  width: 100%;
  object-fit: cover;
  @media (min-width: ${media.desktop}) {
    position: absolute;
    height: ${vwDesktop(415)};
    width: ${vwDesktop(1285)};
    background: gray;
    background-image: ${props =>
      props.background && `url(${props.background})`};
    z-index: 1;
  }
  @media (min-width: ${media.xlarge}) {
    height: 415px;
    width: 1285px;
  }
`;

const TextContainer = styled.div`
  background: white;
  padding-top: ${vw(32)};
  padding-bottom: ${vw(60)};

  text-align: left;
  h2 {
    margin-bottom: ${vw(16)};
  }
  > p {
    margin-bottom: ${vw(24)};
  }
  @media (min-width: ${media.tablet}) {
    height: ${vwTablet(236)};
    width: ${vwTablet(342)};
    padding: ${vwTablet(24)};
    h2 {
      margin-bottom: ${vwTablet(16)};
    }
    > p {
      margin-bottom: ${vwTablet(24)};
    }
  }
  @media (min-width: ${media.desktop}) {
    height: ${vwDesktop(288)};
    width: ${vwDesktop(515)};
    padding-top: ${vwDesktop(48)};
    padding-left: ${vwDesktop(48)};
    padding-right: ${vwDesktop(48)};
    position: relative;
    z-index: 2;
    h2 {
      margin-bottom: ${vwDesktop(24)};
    }
    > p {
      line-height: ${vwDesktop(29)};
      margin-bottom: ${vwDesktop(33)};
    }
    p > a {
      color: ${props =>
        props.color ? props.color : props.theme.color.navyBlue};
      transition: all 0.2s;
      &:hover {
        color: ${({ theme }) => theme.color.coral};
      }
    }
  }
  @media (min-width: ${media.xlarge}) {
    height: 288px;
    width: 515px;
    padding-top: 48px;
    padding-left: 48px;
    padding-right: 48px;
    h2 {
      margin-bottom: 24px;
    }
    > p {
      line-height: 29px;
      margin-bottom: 33px;
    }
  }
`;
