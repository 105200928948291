import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import Page from 'components/Page';
import HeroBackground from 'components/HeroBackground';

const Hero = ({ title, description, image, figures }) => {
  return (
    <Root>
      <Page>
        <TextContainer>
          <h1>{title}</h1>
          <p>{description}</p>
        </TextContainer>
        <HeroBackground image={image} figures={figures} />
      </Page>
    </Root>
  );
};

export default Hero;

Hero.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  img: PropTypes.object,
  figures: PropTypes.array,
};

Hero.defaultProps = {
  image: {
    src: require('src/assets/images/home/hero-home.svg'),
    alt: '',
  },
};

const Root = styled.div`
  > div {
    position: relative;
    padding-top: ${vw(60)};
    padding-bottom: ${vw(180)};
  }
  @media (min-width: ${media.tablet}) {
    > div {
      padding-top: 0;
      padding-bottom: 0;
      height: ${vwTablet(400)};
      height: ${vwTablet(400)};
      /* z-index: 99; */
    }
  }
  @media (min-width: ${media.desktop}) {
    height: ${vwDesktop(668)};
    > div {
      height: ${vwDesktop(668)};
    }
  }
  @media (min-width: ${media.xlarge}) {
    height: 668px;
    > div {
      height: 668px;
    }
  }
`;

const TextContainer = styled.div`
  text-align: left;
  position: relative;
  z-index: 9;
  h1 {
    margin-bottom: ${vw(258)};
  }
  @media (min-width: ${media.tablet}) {
    width: ${vwTablet(341)};
    h1 {
      padding-top: ${vwTablet(100)};
      margin-bottom: ${vwTablet(32)};
    }
  }

  @media (min-width: ${media.desktop}) {
    width: ${vwDesktop(515)};
    h1 {
      padding-top: ${vwDesktop(160)};
      margin-bottom: ${vwDesktop(44)};
    }
    p {
      width: ${vwDesktop(556)};
    }
  }
  @media (min-width: ${media.xlarge}) {
    width: 515px;
    h1 {
      padding-top: 160px;
      margin-bottom: 44px;
    }
    p {
      width: 556px;
    }
  }
`;
