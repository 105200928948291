import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { vw, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

const routes = [
  {
    title: 'About Us',
    path: '/about-us',
  },
  {
    title: 'Communities',
    path: '/communities',
  },
  {
    title: 'Investments',
    path: '/investments',
  },
  {
    title: 'Get in Touch',
    path: '/get-in-touch',
  },
];

const Menu = ({ isMenuOpen, handleMenu }) => {
  return (
    <Root isMenuOpen={isMenuOpen}>
      {routes.map((route, index) => (
        <Link to={route.path} key={index}>
          <h2 onClick={() => handleMenu()}>{route.title}</h2>
        </Link>
      ))}
    </Root>
  );
};

export default Menu;

const Root = styled.main`
  background-color: white;
  height: 100vh;
  position: fixed;
  top: 0;
  left: ${props => (props.isMenuOpen ? 0 : `100%`)};
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${vw(120)};
  background-image: url(${require('src/assets/images/logos/hero-logo-filled.png')});
  background-position: bottom center;
  background-size: ${vw(320)} ${vw(212)};
  background-repeat: no-repeat;
  transition: left 0.4s ease;
  a {
    margin-bottom: ${vw(36)};
  }
  @media (min-width: ${media.tablet}) {
    padding-top: ${vwTablet(200)};
    background-size: ${vwTablet(768)} ${vwTablet(509.7)};
    a {
      margin-bottom: ${vwTablet(60)};
    }
  }
  @media (min-width: ${media.desktop}) {
    display: none;
  }
`;
