import React, { Component } from 'react';
import styled from 'styled-components';

import Hero from 'components/Hero';
import TabDescription from 'components/TabDescription';
import Footer from 'components/Footer';
import WhiteBackground from 'components/WhiteBackground';
import FeaturedImage from './sections/FeaturedImage';
import Beliefs from './sections/Beliefs';

import figures from 'src/data/about-us/figures';

class AboutUs extends Component {
  render() {
    return (
      <Root>
        <Hero
          title="About Us"
          description="We are a Vancouver-based real estate developer and investor that's redefining the relationship with the neighbourhoods we build in."
          image={{
            src: require('src/assets/images/illustrations/hero-logo-stroke.png'),
          }}
          figures={figures}
        />

        <TabDescription
          title="Who We Are"
          description={
            <>
              Along with the homes we build, every community we develop also has
              a commercial component, giving us the opportunity to become part
              of the neighbourhood and contribute to it for years to come.
              <br />
              <br />
              This commitment gives our homeowners the peace of mind of knowing
              that we are not only building a community and a home for their
              future prosperity, but also for our own. It also gives the
              neighbourhood around us confidence that our retail and commercial
              tenants will provide services that contribute to the
              neighbourhood.
              <br />
              <br />
              Being a good neighbour, the desire to be part of the
              neighbourhood, contribute to its wellbeing, and making it a better
              place to live is at the core of who we are.
            </>
          }
          source="about-us"
        />

        <WhiteBackground>
          <FeaturedImage />

          <Beliefs />
        </WhiteBackground>

        <Footer />
      </Root>
    );
  }
}

export default AboutUs;

const Root = styled.main`
  position: Relative;
`;
