import React from 'react';
import styled from 'styled-components';

import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import Button from 'components/Button';
import Page from 'components/Page';

const Hero = () => {
  return (
    <Root>
      <Page>
        <TextContainer>
          <h1>Building Communities Together</h1>
          <Image
            src={require('src/assets/images/home/hero-home.svg')}
            alt="Home page hero graphic"
          />
          <p>
            We believe that great neighbourhoods are built on partnerships, and
            that thoughtful development improves the wellbeing and lifestyle of
            everyone.
          </p>

          <Button path="/about-us" label="About Us" />
        </TextContainer>

        <Image
          src={require('src/assets/images/home/hero-home.svg')}
          alt="Home page hero graphic"
          height={260}
          mobileHidden
        />
      </Page>
    </Root>
  );
};

export default Hero;

const Root = styled.div`
  padding-top: ${vw(60)};
  padding-bottom: ${vw(180)};
  @media (min-width: ${media.tablet}) {
    padding-top: ${vwTablet(60)};
    padding-bottom: ${vwTablet(180)};
    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  @media (min-width: ${media.desktop}) {
    padding-top: ${vwDesktop(100)};
    padding-bottom: ${vwDesktop(364)};
  }
  @media (min-width: ${media.xlarge}) {
    padding-top: 100px;
    padding-bottom: 364px;
  }
`;

const TextContainer = styled.div`
  text-align: left;
  > p {
    margin-bottom: ${vw(32)};
  }
  @media (min-width: ${media.tablet}) {
    width: ${vwTablet(342)};
    h1 {
      margin-bottom: ${vwTablet(32)};
    }
    > p {
      margin-bottom: ${vwTablet(32)};
    }
    img {
      display: none;
    }
  }
  @media (min-width: ${media.desktop}) {
    h1 {
      width: ${vwDesktop(515)};
      margin-bottom: ${vwDesktop(44)};
    }
    > p {
      width: ${vwDesktop(515)};
      margin-bottom: ${vwDesktop(44)};
    }
  }
  @media (min-width: ${media.xlarge}) {
    h1 {
      width: 515px;
      margin-bottom: 44px;
    }
    > p {
      width: 515px;
      margin-bottom: 44px;
    }
  }
`;

const Image = styled.img`
  display: ${props => (props.mobileHidden ? 'none' : 'block')};
  width: 100%;
  height: auto;
  margin-bottom: ${vw(33)};
  @media (min-width: ${media.tablet}) {
    margin-bottom: 0;
    display: ${props => (props.mobileHidden ? 'block' : 'none')};
    width: ${vwTablet(332.8)};
    height: ${vwTablet(278.9)};
  }
  @media (min-width: ${media.desktop}) {
    height: ${vwDesktop(516)};
    width: ${vwDesktop(629)};
    object-fit: contain;
  }
  @media (min-width: ${media.xlarge}) {
    height: 516px;
    width: 629px;
  }
`;
