import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';

const ProjectCard = ({ project }) => {
  const { title, type, location, size, completion, image, tallCard } = project;
  return (
    <Root tallCard={tallCard}>
      <TextContainer>
        <Title>
          <h2>{title}</h2>
          <h3>{type}</h3>
        </Title>

        <Details>
          <Detail>
            <img
              src={require('src/assets/images/icons/location-icon.svg')}
              alt="Location Icon"
              className="location"
            />
            <h4>{location}</h4>
          </Detail>

          <Divider />
          <Detail>
            <img
              src={require('src/assets/images/icons/size-icon.svg')}
              alt="Size Icon"
              className="size"
            />
            <h4>{size === 'TBD' ? 'TBD' : `${size} sq. ft.`}</h4>
          </Detail>
          <Divider />
          <Detail>
            <img
              src={require('src/assets/images/icons/completion-icon.svg')}
              alt="Calendar Icon"
              className="calendar"
            />
            <h4>{completion}</h4>
          </Detail>
        </Details>
      </TextContainer>

      <Image src={image} alt={title} />
    </Root>
  );
};

ProjectCard.propTypes = {
  project: PropTypes.object,
};

ProjectCard.defaultProps = {
  image: require('src/assets/images/home/vancouver-skyline.jpg'),
};

export default ProjectCard;

const Root = styled.div`
  background: ${({ theme }) => theme.color.main};
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: ${vw(40)};
  position: relative;
  &:last-of-type {
    margin-bottom: 0;
  }
  @media (min-width: ${media.tablet}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: ${props => (props.tallCard ? vwTablet(277) : vwTablet(255))};
    width: ${vwTablet(342)};
    margin-bottom: ${vwTablet(24)};
  }
  @media (min-width: ${media.desktop}) {
    width: ${vwDesktop(625)};
    height: ${props => (props.tallCard ? vwDesktop(394) : vwDesktop(370))};
    margin-bottom: ${vwDesktop(30)};
  }
  @media (min-width: ${media.xlarge}) {
    width: 625px;
    height: 370px;
    margin-bottom: 30px;
  }
`;

const TextContainer = styled.div`
  text-align: left;
  padding: ${vw(24)} ${vw(16)};
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(24)} ${vwTablet(16)};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: ${vwTablet(220)};
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(48)};
  }
  @media (min-width: ${media.xlarge}) {
    padding: 48px;
    padding-right: 109px;
  }
`;

const Image = styled.img`
  height: ${vw(180)};
  width: 100%;
  object-fit: cover;
  @media (min-width: ${media.tablet}) {
    height: 100%;
    width: ${vwTablet(122)};
    object-fit: cover;
    position: absolute;
    top: 0;
    right: 0;
  }
  @media (min-width: ${media.desktop}) {
    width: ${vwDesktop(183)};
    background: cornflowerblue;
  }
  @media (min-width: ${media.xlarge}) {
    width: 183px;
  }
`;

const Title = styled.div`
  margin-bottom: ${vw(32)};
  h2 {
    margin-bottom: ${vw(4)};
  }
  h3 {
    color: ${({ theme }) => theme.color.sand};
  }
  @media (min-width: ${media.tablet}) {
    margin-bottom: 0;
    h2 {
      margin-bottom: ${vwTablet(4)};
    }
  }
  @media (min-width: ${media.desktop}) {
    h2 {
      margin-bottom: ${vwDesktop(4)};
      white-space: nowrap;
    }
    h3 {
      font-size: ${vwDesktop(20)};
    }
    p {
      font-size: ${vwDesktop(20)};
      font-weight: bold;
      color: ${({ theme }) => theme.color.gold};
    }
  }
  @media (min-width: ${media.xlarge}) {
    margin-bottom: 48px;
    h2 {
      margin-bottom: 4px;
    }
    h3 {
      font-size: 20px;
    }
    p {
      font-size: 20px;
    }
  }
`;

const Details = styled.div``;

const Detail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    margin-right: ${vw(15)};
    height: ${vw(16)};
    width: ${vw(12)};
    object-fit: contain;
    &.location {
      height: ${vw(16)};
      width: ${vw(12)};
    }
    &.size {
      height: ${vw(14)};
      width: ${vw(14)};
    }
    &.calendar {
      height: ${vw(15)};
      width: ${vw(15)};
    }
  }
  @media (min-width: ${media.tablet}) {
    white-space: nowrap;
    img {
      margin-right: ${vwTablet(11)};
      &.location {
        height: ${vwTablet(16)};
        width: ${vwTablet(12)};
      }
      &.size {
        height: ${vwTablet(14)};
        width: ${vwTablet(14)};
      }
      &.calendar {
        height: ${vwTablet(15)};
        width: ${vwTablet(15)};
      }
    }
  }
  @media (min-width: ${media.desktop}) {
    img {
      margin-right: ${vwDesktop(16)};

      &.location {
        height: ${vwDesktop(16)};
        width: ${vwDesktop(12)};
      }
      &.size {
        height: ${vwDesktop(14)};
        width: ${vwDesktop(14)};
      }
      &.calendar {
        height: ${vwDesktop(15)};
        width: ${vwDesktop(15)};
      }
    }
  }

  @media (min-width: ${media.xlarge}) {
    img {
      margin-right: 16px;

      &.location {
        height: 16px;
        width: 12px;
      }
      &.size {
        height: 14px;
        width: 14px;
      }
      &.calendar {
        height: 15px;
        width: 15px;
      }
    }
  }
`;

const Divider = styled.div`
  /* border: 1px dashed #002e4f; */
  margin: 5px 0;
  height: 1px;
  background: repeating-linear-gradient(
    to right,
    #002e4f 0,
    #002e4f 3px,
    transparent 4px,
    transparent 7px
  );
  /*5px red then 2px transparent -> repeat this!*/
  margin-top: ${vw(16)};
  margin-bottom: ${vw(15)};
  @media (min-width: ${media.tablet}) {
    margin-top: ${vwTablet(16)};
    margin-bottom: ${vwTablet(15)};
  }
  @media (min-width: ${media.desktop}) {
    width: ${vwDesktop(237)};
    margin-top: ${vwDesktop(25)};
    margin-bottom: ${vwDesktop(26)};
  }
  @media (min-width: ${media.xlarge}) {
    width: 237px;
    margin-top: 25px;
    margin-bottom: 26px;
  }
`;
