export default [
  {
    id: 'striped-sweater',
    image: require('src/assets/images/illustrations/people-striped-sweater-guy.svg'),
    mobile: {
      width: 77,
      height: 110,
      top: 116,
      left: 28,
    },
    tablet: {
      height: 150,
      width: 53,
      top: 219,
      left: 153,
    },
    desktop: {
      height: 220,
      width: 77,
      top: 472,
      left: 272,
    },
  },
  {
    id: 'dog-walker',
    image: require('src/assets/images/illustrations/people-dog-walker.svg'),
    mobile: {
      width: 105,
      height: 90,
      top: 116,
      left: 147,
    },
    tablet: {
      height: 120,
      width: 140,
      top: 209,
      left: 308,
    },
    desktop: {
      height: 180,
      width: 209,
      top: 434,
      left: 450,
    },
  },
];
