import React, { Component } from 'react';
import styled from 'styled-components';
import { NavLink, Link } from 'react-router-dom';
import Headroom from 'react-headroom';

import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import Page from 'components/Page';
import Hamburger from 'components/Hamburger';

const routes = [
  {
    title: 'About Us',
    path: '/about-us',
  },
  {
    title: 'Communities',
    path: '/communities',
  },
  {
    title: 'Investments',
    path: '/investments',
  },
  {
    title: 'Get in Touch',
    path: '/get-in-touch',
  },
];

export default class Header extends Component {
  render() {
    return (
      <Root disable={this.props.isMenuOpen}>
        <HeaderContainer hideShadow={this.props.isMenuOpen}>
          <Page>
            <LogoWrapper to="/">
              <Logo
                src={require('src/assets/images/logos/momenta-header-logo.svg')}
                alt="Momenta Logo"
              />
            </LogoWrapper>

            <Links>
              {routes.map((route, index) => (
                <NavLink
                  to={route.path}
                  key={index}
                  activeStyle={{ color: ({ theme }) => theme.color.navyBlue }}
                >
                  <h4>{route.title}</h4>
                </NavLink>
              ))}
            </Links>

            <Hamburger
              isOpen={this.props.isMenuOpen}
              handleClick={this.props.handleMenu}
            />
          </Page>
        </HeaderContainer>
      </Root>
    );
  }
}

const Root = styled(Headroom)`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;

  .headroom {
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    transition: all 0.4s;
  }
`;

const HeaderContainer = styled.div`
  background: white;
  height: ${vw(80)};
  box-shadow: ${props => !props.hideShadow && `0 2px 5px 0 rgba(0, 0, 0, 0.1)`};

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    position: relative;
  }

  @media (min-width: ${media.tablet}) {
    height: ${vwTablet(80)};
  }

  @media (min-width: ${media.desktop}) {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.07);

    height: ${vwDesktop(100)};

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  @media (min-width: ${media.xlarge}) {
    height: 100px;
  }
`;

const Logo = styled.img`
  height: ${vw(48)};
  width: ${vw(63)};
  @media (min-width: ${media.tablet}) {
    height: ${vwTablet(48)};
    width: ${vwTablet(63)};
  }
  @media (min-width: ${media.desktop}) {
    height: ${vwDesktop(67)};
    width: ${vwDesktop(88)};
  }
  @media (min-width: ${media.xlarge}) {
    height: 67px;
    width: 88px;
  }
`;

const LogoWrapper = styled(Link)`
  @media (min-width: ${media.desktop}) {
    margin-right: ${vwDesktop(130)};
  }
  @media (min-width: ${media.xlarge}) {
    margin-right: 130px;
  }
`;

const Links = styled.div`
  display: none;
  @media (min-width: ${media.desktop}) {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    a {
      margin-right: ${vwDesktop(50)};
      font-weight: 500;
    }

    a:last-of-type {
      margin-left: auto;
      margin-right: 0;
    }

    .active {
      color: ${({ theme }) => theme.color.navyBlue};
    }
    h4 {
      color: ${({ theme }) => theme.color.black};
    }
    h4:hover {
      color: ${({ theme }) => theme.color.navyBlue};
    }
  }
  @media (min-width: ${media.xlarge}) {
    a {
      margin-right: 50px;
    }
  }
`;
