import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';

const PageCard = ({ title, description, path, background }) => {
  return (
    <Root background={background}>
      <h2>
        <span>Our</span> {title}
      </h2>
      <p>{description}</p>
      <Link to={path}>
        <p>
          Learn More <span>About Our {title}</span>
        </p>
      </Link>
    </Root>
  );
};

PageCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  path: PropTypes.string,
};

export default PageCard;

const Root = styled.div`
  background-color: #f7f6f4;
  background-image: ${props => props.background && `url(${props.background})`};
  background-position: bottom center;
  background-size: contain;
  background-repeat: no-repeat;
  text-align: left;
  width: 100%;
  height: ${vw(360)};
  margin-bottom: ${vw(24)};
  padding-top: ${vw(32)};
  padding-left: ${vw(16)};
  padding-right: ${vw(16)};
  padding-bottom: ${vw(32)};
  display: flex;
  flex-direction: column;
  h2 {
    letter-spacing: ${vw(0.6)};
    margin-bottom: ${vw(16)};
  }

  a > p {
    color: ${({ theme }) => theme.color.navyBlue};
    font-weight: 500;
    letter-spacing: ${vw(0.4)};
    padding-bottom: ${vw(12)};
    display: inline;
    border-bottom: 2px dashed ${({ theme }) => theme.color.navyBlue};
  }

  a {
    margin-top: auto;
    display: block;
  }

  p > span,
  h2 > span {
    display: none;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: ${media.tablet}) {
    height: ${vwTablet(320)};
    width: ${vwTablet(342)};
    padding-top: ${vwTablet(32)};
    padding-left: ${vwTablet(24)};
    padding-right: ${vwTablet(24)};
    padding-bottom: ${vwTablet(31)};
    margin-bottom: 0;
    h2 {
      margin-bottom: ${vwTablet(16)};
      letter-spacing: ${vwTablet(0.6)};
    }
    a > p {
      padding-bottom: ${vwTablet(11)};
    }
  }

  @media (min-width: ${media.desktop}) {
    height: ${vwDesktop(400)};
    width: ${vwDesktop(625)};
    display: Flex;
    flex-direction: column;
    padding-top: ${vwDesktop(48)};
    padding-left: ${vwDesktop(48)};
    padding-bottom: ${vwDesktop(36)};
    background-size: ${vwDesktop(407)} ${vwDesktop(267)};
    background-position: bottom right;
    h2 {
      margin-bottom: ${vwDesktop(24)};
      letter-spacing: ${vwDesktop(0.8)};
    }
    > p {
      width: ${vwDesktop(467)};
      line-height: 1.5;
    }
    a {
      margin-top: auto;
      display: block;
    }

    a > p {
      display: inline;
      color: ${({ theme }) => theme.color.navyBlue};
      padding-bottom: ${vwDesktop(12)};
      border-bottom: 2px dashed ${({ theme }) => theme.color.navyBlue};
      font-size: ${vwDesktop(18)};
      letter-spacing: ${vwDesktop(0.4)};
      border-bottom: 2px dashed ${({ theme }) => theme.color.navyBlue};

      &:hover {
        border-bottom: 2px dashed ${({ theme }) => theme.color.coral};
      }
    }
    h2 > span,
    p > span {
      display: inline;
    }
  }
  @media (min-width: ${media.xlarge}) {
    height: 400px;
    width: 625px;
    padding-top: 48px;
    padding-left: 48px;
    padding-bottom: 36px;
    background-size: 407px 267px;
    h2 {
      margin-bottom: 24px;
    }
    > p {
      width: 467px;
    }
    a > p {
      padding-bottom: 12px;
      font-size: 18px;
      letter-spacing: 0.4px;
    }
  }
`;
