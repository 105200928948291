import React from 'react';
import styled from 'styled-components';

import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import WhiteBackground from 'components/WhiteBackground';
import Page from 'components/Page';
import PageCard from './../elements/PageCard';

const Pages = () => {
  return (
    <Root>
      <WhiteBackground>
        <Page>
          <PagesContainer>
            <PageCard
              title="Communities"
              description="As an experienced real estate developer, our focus is on creating communities that contribute to the neighbourhoods they are located in."
              path="/communities"
              background={require('src/assets/images/illustrations/hero-communities.png')}
            />
            <PageCard
              title="Investments"
              description="We have a successful track record of partnering with other developers to co-create new communities."
              path="/investments"
              background={require('src/assets/images/illustrations/hero-investments.png')}
            />
          </PagesContainer>
        </Page>
      </WhiteBackground>
    </Root>
  );
};

export default Pages;

const Root = styled.div``;

const PagesContainer = styled.div`
  padding-bottom: ${vw(60)};
  padding-top: ${vw(60)};
  @media (min-width: ${media.tablet}) {
    padding-top: ${vwTablet(336)};
    padding-bottom: ${vwTablet(32)};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  @media (min-width: ${media.desktop}) {
    padding-bottom: ${vwDesktop(48)};
    padding-top: ${vwDesktop(436)};
  }
  @media (min-width: ${media.xlarge}) {
    padding-top: 436px;
    padding-bottom: 48px;
  }
`;
