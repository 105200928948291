import React, { Component } from 'react';
import styled from 'styled-components';

import media from 'src/styles/media';
import Hero from './sections/Hero';
import About from './sections/About';
import Pages from './sections/Pages';
import ContactPanel from 'components/ContactPanel';
import Footer from 'components/Footer';

class Home extends Component {
  render() {
    return (
      <Root>
        <Hero />
        <About />
        <Pages />
        <ContactPanel />
        <Footer />
      </Root>
    );
  }
}

export default Home;

const Root = styled.main`
  @media (min-width: ${media.tablet}) {
    position: relative;
  }
`;
