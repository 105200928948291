import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';
import Page from 'components/Page';
import WhiteBackground from 'components/WhiteBackground';

const TabDescription = ({ title, description, source }) => {
  return (
    <Root source={source}>
      <Page tabletHidden>
        <WhiteBackground>
          <TextContainer>
            <h2>{title}</h2>
            <p className="small-body">{description}</p>
          </TextContainer>
        </WhiteBackground>
      </Page>
    </Root>
  );
};

TabDescription.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default TabDescription;

const Root = styled.div`
  > div {
    position: absolute;
    top: ${props => {
      switch (props.source) {
        case 'get-in-touch':
          return vw(505);
        case 'about-us':
          return vw(561);
        case 'community':
          return vw(632);
        default:
          return vw(632);
      }
    }};
    > div {
      border-top-right-radius: 48px;
    }
    a {
      color: ${({ theme }) => theme.color.navyBlue};
    }
  }
  @media (min-width: ${media.tablet}) {
    > div {
      position: relative;
      top: unset;
    }
    > div div {
      border-top-right-radius: 0;
    }
  }
  @media (min-width: ${media.desktop}) {
    position: absolute;
    top: ${vwDesktop(500)};
    width: ${vwDesktop(814)};
    left: ${vwDesktop(-80)};
    z-index: 999;
    > div {
      padding-right: 0;
    }
    > div div {
      border-top-right-radius: 48px;
      width: ${vwDesktop(814)};
    }
  }
  @media (min-width: ${media.xlarge}) {
    margin: 0 auto;
    top: unset;
    left: unset;
    position: static;
    > div {
      width: calc(734px + ((100vw - 1280px) / 2));
      max-width: none;
      top: 500px;
      left: 0;
      position: absolute;
      z-index: 99;
    }
    > div div {
      width: calc(734px + ((100vw - 1280px) / 2));
    }
  }
`;

const TextContainer = styled.div`
  text-align: left;
  padding-top: ${vw(36)};
  padding-left: ${vw(16)};
  padding-right: ${vw(16)};
  padding-bottom: ${vw(60)};

  h2 {
    margin-bottom: ${vw(16)};
  }

  @media (min-width: ${media.tablet}) {
    padding-top: ${vwTablet(100)};
    padding-left: ${vwTablet(30)};
    padding-right: ${vwTablet(30)};
    padding-bottom: ${vwTablet(60)};
    h2 {
      margin-bottom: ${vwTablet(16)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding-top: ${vwDesktop(48)};
    padding-left: ${vwDesktop(80)};
    padding-bottom: ${vwDesktop(48)};
    padding-right: ${vwDesktop(108)};
    h2 {
      margin-bottom: ${vwDesktop(24)};
    }
    p {
      font-size: ${vwDesktop(16)};
      line-height: 1.63;
      letter-spacing: ${vwDesktop(0.4)};
    }
    a {
      &:hover {
        color: ${({ theme }) => theme.color.coral};
      }
    }
  }
  @media (min-width: ${media.xlarge}) {
    padding-top: 48px;
    padding-left: 0;
    padding-bottom: 48px;
    padding-right: 0;
    margin-left: calc((100vw - 1280px) / 2);
    width: 734px;
    h2 {
      margin-bottom: 24px;
    }
    p {
      font-size: 16px;
      line-height: 1.63;
      letter-spacing: 0.4px;
      width: 626px;
    }
  }
`;
