//Component to add padding to all other components

import React from 'react';
import styled from 'styled-components';

import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';

const Page = ({ children, tabletHidden }) => {
  return <Root tabletHidden={tabletHidden}>{children}</Root>;
};

export default Page;

const Root = styled.div`
  padding-left: ${vw(20)};
  padding-right: ${vw(20)};
  @media (min-width: ${media.tablet}) {
    padding-left: ${props => (props.tabletHidden ? 0 : vwTablet(30))};
    padding-right: ${props => (props.tabletHidden ? 0 : vwTablet(30))};
  }
  @media (min-width: ${media.desktop}) {
    padding-left: ${vwDesktop(80)};
    padding-right: ${vwDesktop(80)};
  }
  @media (min-width: ${media.xlarge}) {
    padding: 0;
    margin: 0 auto;
    max-width: 1280px;
  }
`;
