import React, { Component } from 'react';
import styled from 'styled-components';

import Hero from 'components/Hero';
import TabDescription from 'components/TabDescription';
import Projects from 'components/Projects';
import Footer from 'components/Footer';

import communities from 'src/data/communities/communities';
import figures from 'src/data/communities/figures';

class Communities extends Component {
  render() {
    return (
      <Root>
        <Hero
          title="Our Communities"
          description="As an experienced real estate developer, our focus has always been
            on creating communities that contribute to the neighbourhoods they
            are located in."
          image={{
            src: require('src/assets/images/illustrations/hero-communities.png'),
          }}
          figures={figures}
        />
        <TabDescription
          title="Explore Our Communities"
          description="We focus on adding new housing types so younger generations can stay in the neighbourhood they grew up in, providing opportunities for downsizers to continue the lifestyle they love, and simply adding homes so that others can enjoy the benefits of living in a great community."
          source="communities"
        />
        <Projects projects={communities} />
        <Footer />
      </Root>
    );
  }
}

export default Communities;

const Root = styled.main``;
