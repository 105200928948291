import React from 'react';
import styled from 'styled-components';

import media from 'src/styles/media';

const WhiteBackground = ({ children, tabletHidden }) => {
  return <Root tabletHidden={tabletHidden}>{children}</Root>;
};

export default WhiteBackground;

const Root = styled.div`
  background: white;
  @media (min-width: ${media.tablet}) {
    background: ${props => props.tabletHidden && 'transparent'};
  }
`;
