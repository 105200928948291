export default [
  {
    id: 'guy-with-bike',
    image: require('src/assets/images/illustrations/people-bike-guy.svg'),
    mobile: {
      height: 110,
      width: 62,
      top: 116,
      left: 16,
    },
    tablet: {
      height: 130,
      width: 107,
      top: 220,
      left: 125,
    },
    desktop: {
      height: 190,
      width: 107,
      top: 452,
      left: 297,
    },
  },
  {
    id: 'business-guy',
    image: require('src/assets/images/illustrations/people-business-guy.svg'),
    mobile: {
      height: 90,
      width: 33,
      top: 116,
      left: 194,
    },
    tablet: {
      height: 120,
      width: 107,
      top: 220,
      left: 260,
    },
    desktop: {
      height: 170,
      width: 63,
      top: 442,
      left: 527,
    },
  },
];
