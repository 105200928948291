export default [
  {
    id: 'couple-walking',
    image: require('src/assets/images/illustrations/people-couple.svg'),
    mobile: {
      height: 110,
      width: 113,
      top: 116,
      left: 24,
    },
    tablet: {
      height: 150,
      width: 154,
      top: 219,
      left: 99,
    },
    desktop: {
      height: 210,
      width: 216,
      top: 440,
      left: 254,
    },
  },
];
