import React, { Component } from 'react';
import styled from 'styled-components';

import Page from 'components/Page';
import Footer from 'components/Footer';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

class Terms extends Component {
  render() {
    return (
      <Root>
        <Page>
          <TextContainer>
            <h2>Terms of Use</h2>
            <p className="small-body">
              The material, images, renderings and information on this website
              is provided on an ‘as is’ basis solely for the reader’s general
              knowledge. The information is not intended to be a comprehensive
              review of all matters and developments concerning Momenta
              Properties, and therefore assumes no responsibility and provides
              no warranty, either express or implied, as to its accuracy,
              completeness or currency. In no event will Momenta Properties be
              liable for any special, incidental, indirect or consequential
              damages of any kind, or any damages whatsoever arising out of or
              in connection with the use or performance of this information,
              including without limitation, those resulting from loss of use,
              data or profits, whether or not advised of the possibility of
              damage. This site may contain links to external organizations. We
              do not control the relevance, timeliness, or accuracy of materials
              produced by other organizations, nor do we endorse their views,
              products or services. Your access and use of this website
              constitutes your agreement to be governed by the contents of the
              disclaimer set forth above.
            </p>
          </TextContainer>
        </Page>
        <Footer />
      </Root>
    );
  }
}

export default Terms;

const Root = styled.main`
  min-height: calc(100vh - 80px);
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TextContainer = styled.div`
  padding-top: ${vw(60)};
  padding-bottom: ${vw(60)};
  text-align: left;
  h2 {
    margin-bottom: ${vw(24)};
  }
  @media (min-width: ${media.tablet}) {
    padding-top: ${vwTablet(80)};
    padding-bottom: ${vwTablet(80)};
    h2 {
      margin-bottom: ${vwTablet(24)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding-top: ${vwDesktop(160)};
    padding-bottom: ${vwDesktop(80)};
    h2 {
      margin-bottom: ${vwDesktop(24)};
    }
    p {
      width: ${vwDesktop(843)};
    }
  }
`;
