import React, { Component } from 'react';
import styled from 'styled-components';

import Button from 'components/Button';
import Footer from 'components/Footer';
import Page from 'components/Page';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

class ThankYou extends Component {
  render() {
    return (
      <Root>
        <Page>
          <TextContainer>
            <h2>Thank You</h2>
            <p>
              We will get back to you shortly with more information about
              Momenta Properties.
            </p>
          </TextContainer>

          <Button path="/" label="Back to Home" />
        </Page>
        <Footer />
      </Root>
    );
  }
}

export default ThankYou;

const Root = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 80px);
  height: auto;
  > div:first-child {
    padding-bottom: ${vw(60)};
  }
`;

const TextContainer = styled.div`
  padding-top: ${vw(60)};
  padding-bottom: ${vw(60)};
  text-align: left;
  h2 {
    margin-bottom: ${vw(24)};
  }
  @media (min-width: ${media.tablet}) {
    padding-top: ${vwTablet(80)};
    padding-bottom: ${vwTablet(80)};
    h2 {
      margin-bottom: ${vwTablet(24)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding-top: ${vwDesktop(160)};
    padding-bottom: ${vwDesktop(80)};
    h2 {
      margin-bottom: ${vwDesktop(24)};
    }
    p {
      width: ${vwDesktop(843)};
    }
  }
`;
