import React, { Component } from 'react';
import styled from 'styled-components';

import Hero from 'components/Hero';
import TabDescription from 'components/TabDescription';
import Projects from 'components/Projects';
import Footer from 'components/Footer';

import investments from 'src/data/investments/investments';
import figures from 'src/data/investments/figures';

class Investments extends Component {
  render() {
    return (
      <Root>
        <Hero
          title="Our Investments"
          description="Along with developing and building communities of our own, we also have a successful track record of partnering with other developers to co-create new communities."
          image={{
            src: require('src/assets/images/illustrations/hero-investments.png'),
          }}
          figures={figures}
        />
        <TabDescription
          title="Building Foundations"
          description="Our joint venture partnerships allow us to leverage our experience and expertise to contribute to communities throughout the Lower Mainland."
          source="investments"
        />
        <Projects projects={investments} source="investments" />
        <Footer />
      </Root>
    );
  }
}

export default Investments;

const Root = styled.main``;
