import React, { Component } from 'react';
import styled from 'styled-components';

import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import Input from './elements/Input';
import Button from 'components/Button';

class Form extends Component {
  constructor(props) {
    super(props);
    this.$nameField = React.createRef();
    this.$emailField = React.createRef();
  }

  // handleSubmitForm = (e, name, email) => {
  //   fetch(
  //     'https://i.bamcommunications.ca/server/project/public/api/registrations',
  //     {
  //       method: 'POST',
  //       mode: 'no-cors',
  //       body: JSON.stringify({
  //         project: 'Momenta Properties',
  //         name: name,
  //         email: email,
  //         guid: '',
  //         ip: '',
  //       }),
  //     }
  //   );

  //   e.preventDefault();
  //   console.log(name, email);
  // };

  render() {
    return (
      <Root
        method="POST"
        action="https://i.bamcommunications.ca/mailer/momenta_mailer.php"
        // onSubmit={e =>
        //   this.handleSubmitForm(
        //     e,
        //     this.$nameField.current.value,
        //     this.$emailField.current.value
        //   )
        // }
      >
        <h2>Contact Us</h2>
        <Input
          label="Full Name *"
          name="full_name"
          required
          pattern="^[a-zA-Z' ']+$"
          inputRef={this.$nameField}
        />
        <Input
          label="Phone Number *"
          name="phone_number"
          required
          type="tel"
          pattern="[0-9]{3}?(-|.|'')?[0-9]{3}?(-|.|'')?[0-9]{4}"
        />
        <Input
          label="Email Address *"
          name="email_address"
          required
          type="email"
          pattern="^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$"
          inputRef={this.$emailField}
        />
        <Input label="Message" name="message" multiline />
        <input
          type="hidden"
          name="retURL"
          value={window.location.origin + '/thank-you'}
        />
        <Button type="submit" />
      </Root>
    );
  }
}

export default Form;

const Root = styled.form`
  padding-bottom: ${vw(60)};
  h2 {
    text-align: left;
    margin-bottom: ${vw(16)};
  }
  @media (min-width: ${media.tablet}) {
    padding-bottom: ${vwTablet(60)};
    h2 {
      margin-bottom: ${vwTablet(16)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding-bottom: 0;
    h2 {
      margin-bottom: ${vwDesktop(24)};
    }
  }
  @media (min-width: ${media.xlarge}) {
    h2 {
      margin-bottom: 24px;
    }
  }
`;
