import React, { Component } from 'react';
import styled from 'styled-components';
import PropType from 'prop-types';
import { Parallax } from 'react-scroll-parallax';

import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';

class Figure extends Component {
  static propTypes = {
    parallaxController: PropType.object,
  };

  handleLoad = () => {
    this.props.parallaxController.update();
  };

  render() {
    const { figure, even } = this.props;
    return (
      <Root
        y={!even ? [0, 40] : [40, 0]}
        tagOuter="figure"
        mobile={{
          top: figure.mobile.top,
          left: figure.mobile.left,
        }}
        tablet={{
          top: figure.tablet.top,
          left: figure.tablet.left,
        }}
        desktop={{
          top: figure.desktop.top,
          left: figure.desktop.left,
        }}
      >
        <Person
          image={figure.image}
          mobile={{
            height: figure.mobile.height,
            width: figure.mobile.width,
          }}
          tablet={{
            height: figure.tablet.height,
            width: figure.tablet.width,
          }}
          desktop={{
            height: figure.desktop.height,
            width: figure.desktop.width,
          }}
          onLoad={this.handleLoad}
        />
      </Root>
    );
  }
}

export default Figure;

Figure.PropType = {
  figure: PropType.object,
};

Figure.defaultProps = {
  figure: {
    mobile: {
      height: 0,
      width: 0,
      top: 0,
      left: 0,
    },
  },
};

const Root = styled(Parallax)`
  position: absolute;
  top: ${props => props.mobile.top && vw(props.mobile.top)};
  left: ${props => props.mobile.top && vw(props.mobile.left)};

  z-index: 99;
  @media (min-width: ${media.tablet}) {
    top: ${props => vwTablet(props.tablet.top)};
    left: ${props => vwTablet(props.tablet.left)};
  }
  @media (min-width: ${media.desktop}) {
    top: ${props => vwDesktop(props.desktop.top)};
    left: ${props => vwDesktop(props.desktop.left)};
  }
  @media (min-width: ${media.xlarge}) {
    top: ${props => `${props.desktop.top}px`};
    left: ${props => `${props.desktop.left}px`};
  }
`;

const Person = styled.div`
  background-image: ${props => `url(${props.image})`};
  background-size: contain;
  background-repeat: no-repeat;
  height: ${props => props.mobile.height && vw(props.mobile.height)};
  width: ${props => props.mobile.height && vw(props.mobile.width)};
  @media (min-width: ${media.tablet}) {
    height: ${props => vwDesktop(props.desktop.height)};
    width: ${props => vwDesktop(props.desktop.width)};
  }
  @media (min-width: ${media.desktop}) {
    height: ${props => vwDesktop(props.desktop.height)};
    width: ${props => vwDesktop(props.desktop.width)};
  }
  @media (min-width: ${media.xlarge}) {
    height: ${props => `${props.desktop.height}px`};
    width: ${props => `${props.desktop.width}px`};
  }
`;
