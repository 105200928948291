import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import Figure from 'components/Figure';

const HeroBackground = ({ image, figures }) => {
  return (
    <Root
      background={image.src}
      alt={image.alt}
      location={window.location.pathname}
    >
      {figures &&
        figures.map((figure, index) => (
          <Figure figure={figure} key={index} even={index % 2 === 0} />
        ))}
    </Root>
  );
};

export default HeroBackground;

HeroBackground.propTypes = {
  image: PropTypes.object,
};

HeroBackground.defaultProps = {
  image: {
    src: require('src/assets/images/home/hero-home.svg'),
    alt: '',
  },
};
const Root = styled.div`
  background-image: ${props => `url(${props.background})`};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: ${props => {
    switch (props.location) {
      case '/about-us':
        return vw(107);
      case '/get-in-touch':
        return vw(103);
      default:
        return vw(146);
    }
  }};
  left: ${vw(20)};
  height: ${vw(185.8)};
  width: ${vw(280)};

  @media (min-width: ${media.tablet}) {
    width: ${vwTablet(464)};
    height: ${vwTablet(309)};
    right: ${vwTablet(30)};
    background-position: bottom right;
    bottom: 0;
    top: unset;
    left: unset;
  }
  @media (min-width: ${media.desktop}) {
    height: ${vwDesktop(600)};
    width: ${vwDesktop(903.4)};
    right: 0;
  }
  @media (min-width: ${media.xlarge}) {
    height: 600px;
    width: 903.4px;
    right: -80px;
  }
`;
