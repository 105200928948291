import React, { Component } from 'react';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';
import mapStyles from './styles';

class GoogleMap extends Component {
  static defaultProps = {
    center: {
      lat: 49.2861649,
      lng: -123.1278127,
    },
    zoom: 15,
  };
  render() {
    return (
      <Root>
        <div style={{ height: '100%', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyDA0uXlfqQlmwraObnBYCp1hz4BGs79gFA',
            }}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
            options={{ styles: mapStyles }}
          >
            <PinWrapper lat={49.2860629} lng={-123.125635}>
              <Pin background={require('src/assets/images/icons/pin.png')} />
            </PinWrapper>
          </GoogleMapReact>
        </div>
      </Root>
    );
  }
}

export default GoogleMap;

const Root = styled.div`
  height: ${vw(360)};
  width: ${vw(280)};
  position: relative;
  @media (min-width: ${media.tablet}) {
    height: ${vwTablet(360)};
    width: ${vwTablet(708)};
  }
  @media (min-width: ${media.desktop}) {
    height: ${vwDesktop(500)};
    width: ${vwDesktop(735)};
  }
  @media (min-width: ${media.xlarge}) {
    height: 500px;
    width: 735px;
  }
`;

const Pin = styled.div`
  background: ${props => `url(${props.background})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
  position: absolute;

  top: -100%;
  left: -50%;
`;

const PinWrapper = styled.div`
  position: absolute;
  height: ${vw(36)};
  width: ${vw(24)};
  @media (min-width: ${media.tablet}) {
    height: ${vwTablet(36)};
    width: ${vwTablet(24)};
  }
  @media (min-width: ${media.desktop}) {
    height: ${vwDesktop(36)};
    width: ${vwDesktop(24)};
  }
  @media (min-width: ${media.xlarge}) {
    height: 36px;
    width: 24px;
  }
`;
