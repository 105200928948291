export default [
  {
    title: 'Luce Developments',
    type: 'Cambie - Residential',
    location: 'Vancouver, BC',
    size: '23,000',
    completion: 'Coming 2021',
    image: require('src/assets/images/properties/community-cambie.jpg'),
    tallCard: true,
  },
  {
    title: 'Wave Developments',
    type: 'East 2nd - Mixed-Use Rental',
    location: 'Vancouver, BC',
    size: '78,000',
    completion: 'Coming 2022',
    image: require('src/assets/images/properties/community-east2nd.jpg'),
    tallCard: true,
  },
  {
    title: 'Wave Developments',
    type: 'Dunbar - Mixed-Use Residential',
    location: 'Vancouver, BC',
    size: '25,000',
    completion: 'Coming 2021',
    image: require('src/assets/images/properties/community-dunbar.jpg'),
    tallCard: true,
  },
  {
    title: 'Pine Developments',
    type: 'Dollarton - Single-Family Homes',
    location: 'North Vancouver, BC',
    size: '43,000',
    completion: 'Coming 2019',
    image: require('src/assets/images/properties/community-dollarton.jpg'),
    tallCard: true,
  },
];
