import React from 'react';
import styled from 'styled-components';

import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import Page from 'components/Page';
import WhiteBackground from 'components/WhiteBackground';
import ScrollAnimation from 'components/ScrollAnimation';

const About = () => {
  return (
    <Root>
      <ScrollAnimation>
        <WhiteBackground tabletHidden>
          <Page>
            <AboutContainer
              background={require('src/assets/images/home/vancouver-skyline.jpg')}
            >
              <TextContainer>
                <ScrollAnimation>
                  <Image
                    src={require('src/assets/images/home/vancouver-skyline.jpg')}
                    alt="Vancouver Skyline"
                  />
                </ScrollAnimation>
                <Paragraph>
                  <h2>Who We Are</h2>
                  <p className="small-body">
                    We are a Vancouver-based real estate developer and investor
                    that’s redefining the relationship with the neighbourhoods
                    we build in.
                  </p>
                </Paragraph>

                <Paragraph>
                  <h2>What We Do</h2>
                  <p className="small-body">
                    We are committed to improving everyone's standard of living,
                    reducing environmental impact, and increasing the wellbeing
                    of neighbourhoods.
                  </p>
                </Paragraph>
              </TextContainer>
            </AboutContainer>
          </Page>
        </WhiteBackground>
      </ScrollAnimation>
    </Root>
  );
};

export default About;

const Root = styled.div`
  @media (min-width: ${media.tablet}) {
    > div {
      position: absolute;
      width: 100%;
      top: ${vwTablet(473)};
    }
  }
  @media (min-width: ${media.desktop}) {
    > div {
      top: ${vwDesktop(663)};
    }
  }
  @media (min-width: ${media.xlarge}) {
    > div {
      top: 663px;
    }
  }
`;

const AboutContainer = styled.div`
  padding-top: ${vw(172)};
  @media (min-width: ${media.tablet}) {
    background-color: gray;
    background-image: ${props =>
      props.background && `url(${props.background})`};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: ${vwTablet(32)};
    padding-left: ${vwTablet(61)};
    padding-bottom: ${vwTablet(32)};
  }
  @media (min-width: ${media.desktop}) {
    background-image: ${props =>
      props.background && `url(${props.background})`};
    padding-left: ${vwDesktop(109)};
    padding-top: ${vwDesktop(80)};
    padding-bottom: ${vwDesktop(128)};
  }
  @media (min-width: ${media.xlarge}) {
    padding-left: 109px;
    padding-top: 80px;
    padding-bottom: 128px;
  }
`;

const Image = styled.img`
  height: ${vw(260)};
  width: ${vw(280)};
  object-fit: cover;
  position: absolute;
  top: -${vw(260 + 32)};
  @media (min-width: ${media.tablet}) {
    display: none;
  }
`;

const TextContainer = styled.div`
  background: white;
  text-align: left;
  position: relative;
  h2 {
    margin-bottom: ${vw(16)};
  }
  @media (min-width: ${media.tablet}) {
    width: ${vwTablet(342)};
    padding: ${vwTablet(24)};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h2 {
      margin-bottom: ${vwTablet(16)};
    }
  }
  @media (min-width: ${media.desktop}) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: ${vwDesktop(48)};
    width: ${vwDesktop(436)};
    height: ${vwDesktop(472)};
    h2 {
      margin-bottom: ${vwDesktop(24)};
    }
    p {
      line-height: ${vwDesktop(26)};
    }
    > div:first-of-type {
      display: none;
    }
  }
  @media (min-width: ${media.xlarge}) {
    padding: 48px;
    width: 436px;
    height: 472px;
    h2 {
      margin-bottom: 24px;
    }
    p {
      line-height: 26px;
    }
  }
`;

const Paragraph = styled.div`
  margin-bottom: ${vw(32)};
  &:last-of-type {
    margin-bottom: 0;
  }
  @media (min-width: ${media.tablet}) {
    margin-bottom: ${vwTablet(32)};
  }
  @media (min-width: ${media.desktop}) {
    margin-bottom: 0;
  }
`;
