export default [
  {
    id: 'guy-with-coffee',
    image: require('src/assets/images/illustrations/people-guy-with-drink.svg'),
    mobile: {
      height: 80,
      width: 30,
      top: 987,
      left: 28,
    },
    tablet: {
      height: 80,
      width: 22,
      top: 613,
      left: 66,
    },
    desktop: {
      height: 89,
      width: 33,
      top: 577,
      left: 84,
    },
  },
  {
    id: 'girl-on-bike',
    image: require('src/assets/images/illustrations/people-girl-riding-bike.svg'),
    mobile: {
      height: 80,
      width: 66,
      top: 997,
      left: 162,
    },
    tablet: {
      height: 60,
      width: 49,
      top: 613,
      left: 228,
    },
    desktop: {
      height: 89,
      width: 73,
      top: 577,
      left: 403,
    },
  },
  {
    id: 'couple-embracing',
    image: require('src/assets/images/illustrations/people-couple-embracing.svg'),
    mobile: {},
    tablet: {
      height: 60,
      width: 27,
      top: 603,
      left: 165,
    },
    desktop: {
      height: 88,
      width: 39,
      top: 568,
      left: 309,
    },
  },
  {
    id: 'girl-with-cat',
    image: require('src/assets/images/illustrations/people-girl-with-cat.svg'),
    mobile: {},
    tablet: {
      height: 60,
      width: 22,
      top: 612,
      left: 423,
    },
    desktop: {
      height: 86,
      width: 31,
      top: 570,
      left: 770,
    },
  },
  {
    id: 'skater-boy',
    image: require('src/assets/images/illustrations/people-skater-boy.svg'),
    mobile: {},
    tablet: {
      height: 50,
      width: 49,
      top: 623,
      left: 558,
    },
    desktop: {
      height: 67,
      width: 65,
      top: 599,
      left: 1007,
    },
  },
];
