import React from 'react';
import styled from 'styled-components';

import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import WhiteBackground from 'components/WhiteBackground';
import Page from 'components/Page';
import ProjectCard from 'components/ProjectCard';

const Projects = ({ projects, source }) => {
  return (
    <Root>
      <WhiteBackground>
        <Page>
          <ProjectsContainer source={source}>
            {projects.map((community, index) => (
              <ProjectCard project={community} key={index} />
            ))}
          </ProjectsContainer>
        </Page>
      </WhiteBackground>
    </Root>
  );
};

export default Projects;

const Root = styled.div``;

const ProjectsContainer = styled.div`
  padding-top: ${props => {
    switch (props.source) {
      case 'investments':
        return vw(164);
      case 'communities':
        return vw(260);
      default:
        return vw(260);
    }
  }};
  padding-bottom: ${vw(60)};
  @media (min-width: ${media.tablet}) {
    padding-top: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: ${vwTablet(32)};
    margin-bottom: ${vwTablet(-24)};
  }
  @media (min-width: ${media.desktop}) {
    padding-top: ${vwDesktop(111)};
    margin-bottom: ${vwDesktop(-27)};
    > div {
      flex: 0 1 ${vwDesktop(629)};
    }
  }
  @media (min-width: ${media.xlarge}) {
    padding-top: 111px;
    margin-bottom: -27px;
    > div {
      flex: 0 1 629px;
    }
  }
`;
