export default {
  color: {
    primary: '#00d8ff',
    navyBlue: '#002E4F',
    black: '#262626',
    gray: '#666666',
    gold: '#beb7a4',
    coral: '#FA8A68',
    sand: '#beb7a4',
    main: '#f7f6f4',
  },
  font: {
    body: 'Helvetica Neue',
  },
};
