import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';

const Button = ({ path, label, type }) => {
  return type === 'submit' ? (
    <RootSubmit value="Submit" type="submit" />
  ) : (
    <Root to={path}>
      <p>{label}</p>
    </Root>
  );
};

export default Button;

Button.propTypes = {
  path: PropTypes.string,
  label: PropTypes.string,
};

const buttonStyles = css`
  height: ${vw(48)};
  width: ${vw(188)};
  display: flex;
  flex-direction: row;
  align-items: Center;
  justify-content: center;
  background: ${({ theme }) => theme.color.navyBlue};
  color: white;
  p {
    font-weight: 500;
    letter-spacing: ${vw(0.4)};
  }

  @media (min-width: ${media.tablet}) {
    height: ${vwTablet(48)};
    width: ${vwTablet(188)};
    p {
      letter-spacing: ${vwTablet(0.4)};
    }
  }

  @media (min-width: ${media.desktop}) {
    height: ${vwDesktop(48)};
    width: ${vwDesktop(180)};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    &:hover {
      background: ${({ theme }) => theme.color.coral};
    }
    p {
      font-size: ${vwDesktop(18)};
    }
  }
  @media (min-width: ${media.xlarge}) {
    height: 48px;
    width: 180px;
    p {
      font-size: 18px;
    }
  }
`;

const Root = styled(Link)`
  ${buttonStyles}
`;

const RootSubmit = styled.input`
  ${buttonStyles}
  border: none;
  -webkit-appearance: none;
  font-size: ${vw(18)};
  letter-spacing: ${vw(0.4)};
  font-weight: 500;
  text-align: center;
  @media (min-width: ${media.tablet}) {
    font-size: ${vwTablet(18)};
    letter-spacing: ${vwTablet(0.4)};
    &:hover {
      cursor: pointer;
    }
  }
  @media (min-width: ${media.desktop}) {
    font-size: ${vwDesktop(18)};
    letter-spacing: ${vwDesktop(0.4)};
  }
`;
