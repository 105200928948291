export default [
  {
    id: 'girl-with-phone',
    image: require('src/assets/images/illustrations/people-girl-with-phone.svg'),
    mobile: {
      height: 120,
      width: 49,
      top: 106,
      left: 28,
    },
    tablet: {
      height: 150,
      width: 61,
      top: 219,
      left: 129,
    },
    desktop: {
      height: 210,
      width: 85,
      top: 472,
      left: 307,
    },
  },
  {
    id: 'guy-with-laptop',
    image: require('src/assets/images/illustrations/people-guy-with-laptop.svg'),
    mobile: {
      height: 75,
      width: 53,
      top: 131,
      left: 176,
    },
    tablet: {
      height: 100,
      width: 71,
      top: 228,
      left: 257,
    },
    desktop: {
      height: 138,
      width: 98,
      top: 481,
      left: 509,
    },
  },
];
