import React, { Component } from 'react';
import styled from 'styled-components';

import Page from 'components/Page';
import Footer from 'components/Footer';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

class Privacy extends Component {
  render() {
    return (
      <Root>
        <Page>
          <TextContainer>
            <h2>Privacy Policy</h2>
            <p className="small-body last-paragraph">
              Momenta Properties and each of our affiliates and partnerships is
              committed to maintaining the accuracy, confidentiality and
              security of your personal information. This Privacy Policy
              describes the personal information that Momenta Properties
              collects from or about you, how we use that information, and to
              whom we disclose that information.
            </p>

            <h4>Collecting Personal Information</h4>
            <p className="small-body">
              Momenta Properties collects personal information to better serve
              your needs, whether purchasing products and services from us or
              obtaining service or warranty work. In order to provide you with
              our wide variety of services, products and promotions, we may
              collect the following information:
            </p>
            <ul>
              <li>
                <p className="small-body">
                  Contact and mailing information such as name, address,
                  telephone numbers, fax numbers and email addresses.
                </p>
              </li>

              <li>
                <p className="small-body">
                  The plans, specifications and other particulars about the home
                  that is being built for you.
                </p>
              </li>
              <li>
                <p className="small-body">
                  Municipal and legal descriptions for you home.
                </p>
              </li>
              <li>
                <p className="small-body">
                  Any other relevant information necessary to provide you with
                  our products and services.
                </p>
              </li>
              <li>
                <p className="small-body">
                  We may need to collect other personal information depending on
                  the circumstance, but we will ask you directly and ensure that
                  you agree.
                </p>
              </li>
            </ul>

            <h4>Personal Information Security</h4>
            <p className="small-body">
              Like you, Momenta Properties values our personal information. Part
              of valuing your personal information is making sure that it is
              protected and kept confidential. We achieve this by:
            </p>
            <ul>
              <li>
                <p className="small-body">
                  Having our employees sign a confidentiality agreement.
                </p>
              </li>
              <li>
                <p className="small-body">
                  Having up to date computer security such as passwords,
                  encryption and firewalls.
                </p>
              </li>
              <li>
                <p className="small-body">
                  Where third parties are hired to assist us with providing
                  products and services to you for your home, we have them sign
                  a privacy and confidentiality agreement to ensure that your
                  personal information is protected by them.
                </p>
              </li>
            </ul>

            <h4>Personal Information Updating</h4>
            <p className="small-body last-paragraph">
              Momenta Properties wants to ensure that your personal information
              is always correct up to date. If your personal information changes
              or is incorrect, updates can be made by contacting your
              representative with the change and any other information you feel
              is necessary. We will keep your information as long as is
              necessary to continue to meet your customer needs and any legal
              requirements we may have. However, once your personal information
              no longer needed, we will destroy it.
            </p>

            <h4>Personal Data</h4>
            <p className="small-body">
              We only collect Personal Data from you, such as first and last
              name, phone number, e-mail, when you register to receive
              information. We collect data about your activities that does not
              personally or directly identify you when you visit our website, or
              the websites and online services where we display advertisements.
              This information may include the content you view, the date and
              time that you view this content, the products you purchase, or
              your location information associated with your IP address. We use
              the information we collect to serve you more relevant
              advertisements (referred to as “Retargeting”). We collect
              information about where you saw the ads we serve you and what ads
              you clicked on.
            </p>
            <p className="small-body">
              As you browse the website, online ad networks we work with may
              place anonymous cookies on your computer, and use similar
              technologies, in order to understand your interests based on your
              (anonymous) online activities, and thus to tailor more relevant
              ads to you. If you do not wish to receive such tailored
              advertising, you can visit this page to opt out of most companies
              that engage in such advertising. (This will not prevent you from
              seeing ads; the ads simply will not be delivered through these
              targeting methods.)
            </p>

            <p className="small-body">
              We do not target ads to you based on sensitive personal data, such
              as information related to race or ethnic origins, political
              opinions, religious beliefs or other beliefs of a similar nature,
              trade union membership, physical or mental health or condition or
              sexual life. We may target ads based on data provided by
              Advertisers or partners alone or in combination with the data we
              collect ourselves. Any data used to serve targeted advertisements
              is de-identified and is not used to personally or directly
              identify you. In order to allow us to reach the best inventory
              online, we work with third party advertising companies (our
              “Advertising Partners”) to help us recognize you and serve
              relevant advertisements to you when you visit a website or online
              service in their network. We also work with Advertising Partners
              who help us recognize you across different devices in order to
              show you relevant advertisements. Our Advertising Partners may
              collect information about your activities on our website, and
              other websites or online services in their networks. We also work
              with third party companies to assist us with website analytics
              such as evaluating the use and operation of our website so that we
              can continue to enhance the website and our services.
            </p>

            <p className="small-body">
              We and our third-party partners use session cookies and persistent
              cookies to make it easier for you to navigate and enhance the
              experience of our site, and to monitor and evaluate our website’s
              operation and use.
            </p>

            <p className="small-body">
              To modify or delete your personal information, or to opt out of
              future communications, please contact us at:
            </p>

            <p className="small-body">
              <strong>Momenta Properties</strong>
              <br />
              1108-1166 Alberni Street
              <br />
              Vancouver, BC V6E 3Z3
              <br />
              <br />
              Email:{' '}
              <a
                href="mailto:privacy@momentaproperties.ca"
                target="_blank"
                rel="noopener noreferrer"
              >
                privacy@momentaproperties.ca
              </a>
            </p>
          </TextContainer>
        </Page>
        <Footer />
      </Root>
    );
  }
}

export default Privacy;

const Root = styled.main``;

const TextContainer = styled.div`
  padding-top: ${vw(60)};
  padding-bottom: ${vw(60)};
  text-align: left;
  h2 {
    margin-bottom: ${vw(24)};
  }
  h4 {
    margin-bottom: ${vw(16)};
  }
  > p {
    margin-bottom: ${vw(16)};
    &.last-paragraph {
      margin-bottom: ${vw(32)};
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  li {
    margin-left: ${vw(20)};
  }
  ul {
    margin-bottom: ${vw(32)};
  }
  a {
    color: ${({ theme }) => theme.color.navyBlue};
  }
  @media (min-width: ${media.tablet}) {
    padding-top: ${vwTablet(80)};
    padding-bottom: ${vwTablet(80)};
    h2 {
      margin-bottom: ${vwTablet(24)};
    }
    > p {
      margin-bottom: ${vwTablet(16)};
      &.last-paragraph {
        margin-bottom: ${vwTablet(32)};
      }
    }
    h4 {
      margin-bottom: ${vwTablet(16)};
    }
    ul {
      margin-bottom: ${vwTablet(32)};
    }
  }
  @media (min-width: ${media.desktop}) {
    width: ${vwDesktop(843)};
    padding-top: ${vwDesktop(160)};
    padding-bottom: ${vwDesktop(80)};
    h2 {
      margin-bottom: ${vwDesktop(24)};
    }
    h4 {
      margin-bottom: ${vwDesktop(16)};
    }
    > p {
      margin-bottom: ${vwDesktop(16)};
      &.last-paragraph {
        margin-bottom: ${vwDesktop(32)};
      }
    }
    ul {
      margin-bottom: ${vwDesktop(32)};
    }
  }
`;
