import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import Page from 'components/Page';

const routes = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'About Us',
    path: '/about-us',
  },
  {
    title: 'Communities',
    path: '/communities',
  },
  {
    title: 'Investments',
    path: '/investments',
  },
  {
    title: 'Get in Touch',
    path: '/get-in-touch',
  },
];

const Footer = () => {
  return (
    <Root>
      <Page>
        <Logo to="/">
          <img
            src={require('src/assets/images/logos/momenta-footer-logo.svg')}
            alt="Momenta Logo"
          />
        </Logo>
        <TopContainer>
          <ContactUs>
            <h4>Contact Us</h4>
            <a href="tel:604-771-3813">
              <p className="small-body">604-771-3813</p>
            </a>
            <a
              href="mailto:info@momentaproperties.ca"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="small-body">info@momentaproperties.ca</p>
            </a>
            <a
              href="https://www.google.com/maps/place/1166+Alberni+St,+Vancouver,+BC+V6E+1A5/data=!4m2!3m1!1s0x54867180de8abc85:0x875385a65cf308d9?sa=X&ved=2ahUKEwiJt4fywaDiAhW0HzQIHYBSAPUQ8gEwAHoECAoQAQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="small-body">
                1108-1166 Alberni Street<span>,</span> <br />
                Vancouver, BC V6E 3Z3
              </p>
            </a>
          </ContactUs>

          <Legal>
            <h4>Legal</h4>
            <Link to="/terms-of-use">
              <p className="small-body">Terms of Use</p>
            </Link>
            <Link to="/privacy-policy">
              <p className="small-body">Privacy Policy</p>
            </Link>
          </Legal>

          <Logo to="/" mobilehidden="true">
            <img
              src={require('src/assets/images/logos/momenta-footer-logo.svg')}
              alt="Momenta Logo"
            />
          </Logo>
        </TopContainer>

        <Navigation>
          {routes.map((route, index) => (
            <Link to={route.path} key={index}>
              <p className="small-body">{route.title}</p>
            </Link>
          ))}
        </Navigation>
      </Page>
    </Root>
  );
};

export default Footer;

const listStyle = css`
  text-align: left;
  h4 {
    margin-bottom: ${vw(16)};
  }
  a > p {
    color: ${({ theme }) => theme.color.gray};
  }
  p {
    margin-bottom: ${vw(16)};
  }
  @media (min-width: ${media.tablet}) {
    h4 {
      margin-bottom: ${vwTablet(12)};
    }
    p {
      margin-bottom: ${vwTablet(12)};
    }
  }
  @media (min-width: ${media.desktop}) {
    display: flex;
    flex-direction: column;
    text-align: left;
    transition: all 0.2s;

    h4 {
      margin-bottom: ${vwDesktop(24)};
    }
    p {
      margin-bottom: ${vwDesktop(8)};
    }
    a:hover p {
      color: ${({ theme }) => theme.color.navyBlue};
    }
  }
  @media (min-width: ${media.xlarge}) {
    h4 {
      margin-bottom: 24px;
    }
    p {
      margin-bottom: 8px;
    }
  }
`;

const Root = styled.div`
  background: white;
  padding-bottom: ${vw(40)};
  padding-top: ${vw(40)};
  @media (min-width: ${media.tablet}) {
    padding-top: ${vwTablet(40)};
    padding-bottom: ${vwTablet(24)};
    padding-left: ${vwTablet(61)};
    padding-right: ${vwTablet(61)};
  }
  @media (min-width: ${media.desktop}) {
    padding-left: ${vwDesktop(109)};
    padding-right: ${vwDesktop(109)};
    padding-bottom: ${vwDesktop(24)};
    padding-top: ${vwDesktop(60)};
  }
  @media (min-width: ${media.xlarge}) {
    padding: 0;
    > div {
      padding: 60px 109px 24px 109px;
    }
  }
`;

const TopContainer = styled.div`
  span {
    display: none;
  }
  @media (min-width: ${media.tablet}) {
    display: flex;
    flex-direction: row;
    padding-bottom: ${vwTablet(40)};
  }
  @media (min-width: ${media.desktop}) {
    padding-bottom: ${vwDesktop(60)};
  }
  @media (min-width: ${media.xlarge}) {
    padding-bottom: 60px;
  }
`;

const ContactUs = styled.div`
  ${listStyle}
  margin-bottom:${vw(40)};
  @media(min-width:${media.tablet}){
    margin-right:${vwTablet(25)};
    margin-bottom:0;
    width:${vwTablet(219)};
  }
  @media(min-width:${media.desktop}){
    width:${vwDesktop(373)};
    margin-right:${vwDesktop(178)};
   a >p{
white-space:nowrap;
    }
    br{
      display:none;
    }
  }
  @media(min-width:${media.xlarge}){
    width: 373px;
    margin-right: 178px;
  }
`;

const Legal = styled.div`
  ${listStyle}
`;

const Logo = styled(Link)`
  display: ${props => (props.mobilehidden ? 'none' : 'block')};
  img {
    height: ${vw(106.6)};
    width: ${vw(119.4)};
    margin-bottom: ${vw(40.9)};
  }
  @media (min-width: ${media.tablet}) {
    display: ${props => (props.mobilehidden ? 'block' : 'none')};
    margin-left: auto;
    img {
      height: ${vwTablet(109)};
      width: ${vwTablet(121)};
      margin-bottom: 0;
    }
  }
  @media (min-width: ${media.desktop}) {
    img {
      height: ${vwDesktop(131)};
      width: ${vwDesktop(146)};
    }
  }
  @media (min-width: ${media.xlarge}) {
    img {
      height: 131px;
      width: 146px;
    }
  }
`;

const Navigation = styled.div`
  display: none;
  @media (min-width: ${media.tablet}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid ${({ theme }) => theme.color.gray};
    padding-top: ${vwTablet(16)};
  }
  @media (min-width: ${media.desktop}) {
    padding-top: ${vwDesktop(24)};
    transition: all 0.2s;
    a:hover {
      color: ${({ theme }) => theme.color.navyBlue};
    }
  }
  @media (min-width: ${media.xlarge}) {
    padding-top: 24px;
  }
`;
