import React from 'react';
import styled from 'styled-components';

import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import WhiteBackground from 'components/WhiteBackground';
import Page from 'components/Page';
import Figure from 'components/Figure';

import figures from 'src/data/about-us/figuresBeliefs';

const Beliefs = () => {
  return (
    <Root>
      <WhiteBackground>
        <Page>
          <BeliefsContainer
            background={require('src/assets/images/illustrations/city.png')}
          >
            <h2>What We Believe</h2>
            <TextContainer>
              <Paragraph>
                <h4>Trusted Partnerships</h4>
                <p className="small-body">
                  Working in, and investing in communities together is one of
                  our core beliefs. It is a conviction that is built on the
                  philosophy that we are stronger when we work together, and
                  that through partnerships, we can achieve our goals faster and
                  more effectively.
                </p>
              </Paragraph>
              <Paragraph>
                <h4>Enduring Commitment</h4>
                <p className="small-body">
                  Participating and contributing over the long term is
                  fundamental to how we work. This enduring commitment begins
                  with inherent craftsmanship, extends to the quality of
                  tenants, and ensures that the promise we made to you when you
                  bought your Momenta home is everlasting.
                </p>
              </Paragraph>

              <Paragraph>
                <h4>Community Wellbeing</h4>
                <p className="small-body">
                  Creating healthier, happier communities where quality of life
                  is placed at the forefront is at the heart of what we believe.
                  As a fundamental building block of our communities, our focus
                  on community wellbeing is built on creating a lifestyle that
                  is more accommodating, richer and varied.
                </p>
              </Paragraph>
            </TextContainer>

            {figures.map((figure, index) => (
              <Figure figure={figure} key={index} even={index % 2 === 0} />
            ))}
          </BeliefsContainer>
        </Page>
      </WhiteBackground>
    </Root>
  );
};

export default Beliefs;

const Root = styled.div`
  padding-bottom: ${vw(81)};
  h2 {
    text-align: left;
  }
  @media (min-width: ${media.tablet}) {
    padding-bottom: ${vwTablet(53)};
  }
  @media (min-width: ${media.desktop}) {
    padding-bottom: ${vwDesktop(80.4)};
  }
  @media (min-width: ${media.xlarge}) {
    padding-bottom: 80.4px;
  }
`;

const TextContainer = styled.div`
  text-align: left;
  @media (min-width: ${media.desktop}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Paragraph = styled.div`
  margin-bottom: ${vw(32)};
  &:last-of-type {
    margin-bottom: 0;
  }
  @media (min-width: ${media.tablet}) {
    margin-bottom: ${vwTablet(32)};
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  @media (min-width: ${media.desktop}) {
    width: ${vwDesktop(355)};
    margin-bottom: 0;
    h4 {
      margin-bottom: ${vwDesktop(16)};
    }
  }
  @media (min-width: ${media.xlarge}) {
    width: 355px;

    h4 {
      margin-bottom: 16px;
    }
  }
`;

const BeliefsContainer = styled.div`
  background: #f7f6f4;
  padding-top: ${vw(32)};
  padding-left: ${vw(16)};
  padding-right: ${vw(15)};
  padding-bottom: ${vw(205.7)};
  background-image: ${props => `url(${props.background})`};
  background-size: auto ${vw(158.9)};
  background-position: bottom center;
  background-repeat: no-repeat;
  position: relative;
  h2 {
    margin-bottom: ${vw(32)};
  }
  h4 {
    margin-bottom: ${vw(12)};
  }
  @media (min-width: ${media.tablet}) {
    padding-top: ${vwTablet(32)};
    padding-left: ${vwTablet(24)};
    padding-right: ${vwTablet(24)};
    padding-bottom: ${vwTablet(164)};
    background-size: auto ${vwTablet(119.8)};
    h2 {
      margin-bottom: ${vwTablet(32)};
    }
    h4 {
      margin-bottom: ${vwTablet(12)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding-top: ${vwDesktop(48)};
    padding-left: ${vwDesktop(48)};
    padding-right: ${vwDesktop(48)};
    padding-bottom: ${vwDesktop(291.9)};
    background-size: auto ${vwDesktop(213.6)};
    h2 {
      margin-bottom: ${vwDesktop(48)};
    }
  }
  @media (min-width: ${media.xlarge}) {
    padding: 48px;
    padding-bottom: 291.9px;
    background-size: auto 213px;
    h2 {
      margin-bottom: 48px;
    }
  }
`;
