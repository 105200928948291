export default [
  {
    title: 'Cambie',
    type: 'Residential',
    location: 'Vancouver, BC',
    size: '23,000',
    completion: 'Coming 2021',
    image: require('src/assets/images/properties/community-cambie.jpg'),
  },
  {
    title: 'East 2nd',
    type: 'Mixed-Use Rental',
    location: 'Vancouver, BC',
    size: '78,000',
    completion: 'Coming 2022',
    image: require('src/assets/images/properties/community-east2nd.jpg'),
  },
  {
    title: 'Dunbar',
    type: 'Mixed-use Residential',
    location: 'Vancouver, BC',
    size: '25,000',
    completion: 'Coming 2021',
    image: require('src/assets/images/properties/community-dunbar.jpg'),
    tallCard: true,
  },
  {
    title: 'Dollarton',
    type: 'Single-Family Homes',
    location: 'North Vancouver, BC',
    size: '43,000',
    completion: 'Coming 2019',
    image: require('src/assets/images/properties/community-dollarton.jpg'),
    tallCard: true,
  },
];
