import React from 'react';
import styled from 'styled-components';

import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';

const Input = ({
  name,
  label,
  multiline,
  required,
  type,
  pattern,
  inputRef,
}) => {
  return (
    <Root>
      <label htmlFor={name}>{label}</label>
      {multiline ? (
        <textarea rows="3" required={required} />
      ) : (
        <input
          type={type ? type : 'text'}
          name={name}
          required={required}
          pattern={pattern}
          ref={inputRef}
        />
      )}
    </Root>
  );
};

export default Input;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: ${vw(16)};
  &:last-child {
    margin-bottom: ${vw(24)};
  }
  input {
    background: #e7e4dc;
    padding: ${vw(12)};
    font-size: ${vw(15)};
    font-weight: 300;
    line-height: 1.6;
    letter-spacing: ${vw(0.4)};
    -webkit-appearance: none;
    border: none;
  }
  textarea {
    background: #e7e4dc;
    height: ${vw(96)};
    border: none;
  }

  @media (min-width: ${media.tablet}) {
    margin-bottom: ${vwTablet(16)};
    width: ${vwTablet(464)};

    &:last-child {
      margin-bottom: ${vwTablet(24)};
    }
    input {
      padding: ${vwTablet(12)};
      font-size: ${vwTablet(15)};
      letter-spacing: ${vwTablet(0.4)};
    }
    textarea {
      height: ${vwTablet(96)};
      padding: ${vwTablet(12)};
      font-size: ${vwTablet(15)};
      letter-spacing: ${vwTablet(0.4)};
    }
    label {
      font-size: ${vwTablet(15)};
      line-height: 1.6;
    }
  }

  @media (min-width: ${media.desktop}) {
    margin-bottom: ${vwDesktop(16)};
    width: ${vwDesktop(406)};
    &:last-child {
      margin-bottom: ${vwDesktop(24)};
    }
    input {
      padding: ${vwDesktop(12)};
      font-size: ${vwDesktop(15)};
      letter-spacing: ${vwDesktop(0.4)};
    }
    textarea {
      padding: ${vwDesktop(12)};
      font-size: ${vwDesktop(15)};
      letter-spacing: ${vwDesktop(0.4)};
      height: ${vwDesktop(96)};
      margin-bottom: ${vwDesktop(24)};
    }
    label {
      font-size: ${vwDesktop(16)};
    }
  }
  @media (min-width: ${media.xlarge}) {
    margin-bottom: 16px;
    width: 406px;
    &:last-child {
      margin-bottom: 24px;
    }
    input {
      padding: 12px;
      font-size: 15px;
      letter-spacing: 0.4px;
    }
    textarea {
      padding: 12px;
      font-size: 15px;
      letter-spacing: 0.4px;
      height: 96px;
      margin-bottom: 24px;
    }
    label {
      font-size: 16px;
    }
  }
`;
